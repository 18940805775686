<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left">
        <el-button class="button" @click="reportForm" type="primary">报表</el-button>
      </div>
      <div class="pageFucView_right">
        <el-form style="display: flex" label-width="110px" label-position="left">
          <el-form-item label="搜索方式:">
            <el-input placeholder="请输入内容" v-model="search.name" class="input-with-select">
              <el-select v-model="search.state" slot="prepend" placeholder="请选择">
                <el-option label="订单号" value="1"></el-option>
                <el-option label="外部订单号" value="2"></el-option>
                <el-option label="收货人手机" value="3"></el-option>
                <el-option label="收货人地址" value="4"></el-option>
                <el-option label="商品名称" value="5"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="关注时间段:">
            <el-date-picker
                v-model="search.time"
                :picker-options="pickerOptions"
                @change="getTimeSection"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="searchHandle">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="resetHandle">重置</el-button>
        </el-form>
      </div>
    </div>

    <el-table
        :data="list"
        style="width: 100%"
        border>
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="user_id"
          label="用户">
      </el-table-column>
      <el-table-column
          align="center"
          prop="nickname"
          label="手机号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="email"
          label="Email">
      </el-table-column>
      <el-table-column
          align="center"
          prop="goods_obj.name"
          label="商品名">
      </el-table-column>
      <el-table-column
          align="center"
          prop="goods_obj.id"
          label="商品编号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="add_time"
          label="添加时间">
      </el-table-column>
      <el-table-column
          align="center"
          :formatter="formatterState"
          prop="state"
          label="提醒状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleDelete(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="search['per-page']" :current-page.sync="search.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
  </div>
</template>
<script>

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '24小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近3天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近7天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近30天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      query_time: [],
      selectRows: '',
      pageCount: 0,
      search: {},
      list: [],
    }
  },
  created() {
  },
  methods: {
    searchHandle(){},
    resetHandle(){},
    reportForm() {
      this.$router.push({
        path: 'arrivalReminderReport',
      })
    },
    pageChangeHandle() {},
    handleDelete(id){},
    getTimeSection(){},

    formatterState: function (row) {
      return row.state == '0'
          ? '未发送'
          : row.state == '1'
              ? '已发送'
              : '暂无'
    },
  }
}

</script>
<style>
.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
  margin-bottom: 80px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
.el-form-item {
  margin-bottom: 0px;
  margin-right: 10px;
}
</style>
